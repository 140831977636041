<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row dense>
      <v-col cols="12">
        <base-material-card color="success" inline title="Împrumuturi active" icon="mdi-playlist-check">
          <table-management
            ref="tableloan"
            name="tmdatabasesl"
            table="ebib_tm_lib_loan"
            view-table="5facdd24fbf23ba802de22df"
            :where="`&quot;cln_barcode&quot;:&quot;` + barcodeUser + `&quot;`"
          />
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <base-material-card color="success" inline title="Istoric împrumuturi" icon="mdi-bookmark-outline">
          <table-management
            ref="tableloanhistory"
            name="tmdatabaseslh"
            table="ebib_tm_lib_loan_history"
            view-table="5facdec1fbf23ba802de22e0"
            :where="`&quot;cln_barcode&quot;:&quot;` + barcodeUser + `&quot;`"
          />
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <base-material-card color="success" inline title="Rezervări active" icon="mdi-playlist-play">
          <table-management
            ref="tablereservation"
            name="tmdatabasesr"
            table="ebib_tm_lib_reserve"
            view-table="5facdefdfbf23ba802de22e1"
            :where="`&quot;cln_barcode&quot;:&quot;` + barcodeUser + `&quot;`"
          />
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <base-material-card color="success" inline title="Rezervare istoric" icon="mdi-bookmark">
          <table-management
            ref="tablereservationhistory"
            name="tmdatabasesrh"
            table="ebib_tm_lib_reserve_history"
            view-table="5facdf26fbf23ba802de22e2"
            :where="`&quot;cln_barcode&quot;:&quot;` + barcodeUser + `&quot;`"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "../../../../plugins/axios"
import TableManagement from "../../../../components/containers/TableManagement"
export default {
  name: "DashboardDashboard",
  components: { TableManagement },
  data() {
    return {
      totalResults: 1000000,
      itemsPerPage: 12,
      selectedView: 0,
      page: 1,
      bottom: false,
      category: "",
      records: []
    }
  },
  computed: {
    companyId() {
      return this.$store.getters.companyId
    },
    barcodeUser() {
      return this.$store.getters.userBarcode
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    breadcrumbs() {
      const arr = [
        {
          text: "Acasă",
          disabled: false,
          href: "/"
        }
      ]
      if (this.category != "") {
        arr.push({
          text: "Articole",
          disabled: false,
          href: "/art"
        })
      }
      return arr
    }
  },
  methods: {
    getRecords() {
      if (this.itemsPerPage * (this.page - 1) <= this.totalResults) {
        let cat = ""
        if (this.category != "") {
          cat = '&where={"ctg":"' + this.category + '"}'
        }
        axios.get("ebib_marc_art?max_results=" + this.itemsPerPage + "&page=" + this.page + cat).then(response => {
          this.totalResults = response.data._meta.total
          //this.records = []
          response.data._items.map(itm => {
            const rec = {}
            rec._id = itm._id
            for (const itms in itm) {
              if (itms != "fields") {
                rec[itms] = itm[itms]
              }
            }
            if (itm.fields["200"]) {
              rec.title = itm.fields["200"][0].a[0].val
            }
            for (const flds in itm.fields) {
              for (const fld in itm.fields[flds]) {
                for (const sub in itm.fields[flds][fld]) {
                  if (sub.length == 1) {
                    for (const subs in itm.fields[flds][fld][sub]) {
                      if (rec["fields." + flds + "." + sub + ".val"]) {
                        rec["fields." + flds + "." + sub + ".val"] += ", " + itm.fields[flds][fld][sub][subs].val
                      } else {
                        rec["fields." + flds + "." + sub + ".val"] = itm.fields[flds][fld][sub][subs].val
                      }
                    }
                  } else {
                    rec["fields." + flds + "." + sub] = itm.fields[flds][fld][sub]
                  }
                }
              }
            }
            this.records.push(rec)
          })
          this.$nextTick(() => {
            if (this.bottomVisible()) {
              this.getRecords()
            }
          })
        })
        this.page++
      }
    }
  }
}
</script>
<style></style>
